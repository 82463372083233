import React from 'react';
import { Container } from '@mui/material';

function Promotions() {
    return (
        <Container maxWidth="lg" sx={{ padding: { xs: 2, md: 4 }, backgroundColor: 'background.default' }}>
            
        </Container>
    );
}

export default Promotions;