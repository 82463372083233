import React from 'react';
import { Box, Typography } from '@mui/material';

function Spielerschutz() {
  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h1" component="h1" gutterBottom>
        Spielerschutz
      </Typography>

      <Typography variant="body1" paragraph>
        Bei <strong>EinfachNurZocken</strong> setzen wir uns für den Schutz unserer Spieler ein und fördern verantwortungsbewusstes Spielen. Glücksspiel kann eine unterhaltsame Freizeitaktivität sein, birgt jedoch auch Risiken. Um sicherzustellen, dass Glücksspiel nicht zum Problem wird, bieten wir umfassende Informationen und Hilfsmittel, die Spielern helfen, die Kontrolle über ihr Spielverhalten zu behalten.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Erkennen von problematischem Spielverhalten
      </Typography>

      <Typography variant="body1" paragraph>
        Glücksspiel kann zur Sucht führen, und es ist wichtig, die Anzeichen von problematischem Spielverhalten frühzeitig zu erkennen. Hier sind einige Warnzeichen, die darauf hindeuten, dass Glücksspiel zu einem Problem geworden sein könnte:
      </Typography>
      <ul>
        <li>Sie spielen häufiger und länger als geplant.</li>
        <li>Sie geben mehr Geld aus, als Sie sich leisten können.</li>
        <li>Sie leihen sich Geld, um zu spielen.</li>
        <li>Sie vernachlässigen berufliche, soziale oder familiäre Verpflichtungen.</li>
        <li>Sie versuchen, Verluste durch weiteres Spielen auszugleichen.</li>
      </ul>

      <Typography variant="body1" paragraph>
        Wenn Sie bei sich oder bei anderen Anzeichen eines problematischen Spielverhaltens feststellen, sollten Sie handeln und Hilfe in Anspruch nehmen.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Tools und Maßnahmen zum Spielerschutz
      </Typography>

      <Typography variant="body1" paragraph>
        Wir bei <strong>EinfachNurZocken</strong> arbeiten mit lizenzierten und regulierten Online Spielotheken und Sportwettenanbietern zusammen, die strenge Spielerschutzmaßnahmen implementieren. Hier sind einige der Maßnahmen, die Ihnen helfen können, verantwortungsbewusst zu spielen:
      </Typography>

      <Typography variant="h3" component="h3" gutterBottom>
        1. Selbstlimitierung
      </Typography>
      <Typography variant="body1" paragraph>
        Viele Anbieter ermöglichen es Spielern, persönliche Limits für Einzahlungen, Verluste oder Spielzeiten festzulegen. Diese Limits helfen Ihnen, Ihre Spielaktivität zu kontrollieren und verhindern, dass Sie mehr ausgeben, als Sie sich leisten können.
      </Typography>

      <Typography variant="h3" component="h3" gutterBottom>
        2. Selbstausschluss
      </Typography>
      <Typography variant="body1" paragraph>
        Wenn Sie eine Pause vom Spielen einlegen oder sich vorübergehend oder dauerhaft vom Glücksspiel ausschließen möchten, bieten viele Plattformen eine <strong>Selbstausschluss-Funktion</strong> an.
      </Typography>

      <Typography variant="h3" component="h3" gutterBottom>
        3. Realitäts-Checks
      </Typography>
      <Typography variant="body1" paragraph>
        Einige Anbieter bieten <strong>Realitäts-Checks</strong> an, die Sie in regelmäßigen Abständen daran erinnern, wie lange Sie bereits spielen.
      </Typography>

      <Typography variant="h3" component="h3" gutterBottom>
        4. Zeitbeschränkungen
      </Typography>
      <Typography variant="body1" paragraph>
        Mit Zeitlimits können Sie Ihre tägliche oder wöchentliche Spielzeit begrenzen, um problematisches Spielverhalten zu verhindern.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Unterstützung und Hilfsorganisationen
      </Typography>
      <Typography variant="body1" paragraph>
        Wenn Sie das Gefühl haben, dass Sie oder jemand in Ihrem Umfeld Schwierigkeiten mit dem Glücksspiel hat, gibt es zahlreiche Organisationen, die Unterstützung bieten:
      </Typography>
      <ul>
        <li>Bundeszentrale für gesundheitliche Aufklärung (BZgA)</li>
        <li>Gamblers Anonymous</li>
        <li>Beratung durch Sucht- und Schuldnerberatungsstellen</li>
      </ul>

      <Typography variant="h2" component="h2" gutterBottom>
        Glücksspiel und Jugendschutz
      </Typography>
      <Typography variant="body1" paragraph>
        Der Zugang zu <strong>Online Spielotheken</strong> und <strong>Sportwetten</strong> ist strikt auf volljährige Personen ab 18 Jahren beschränkt. Verantwortungsbewusstes Glücksspiel bedeutet auch, den Jugendschutz ernst zu nehmen.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Lizenzen und Regulierung
      </Typography>
      <Typography variant="body1" paragraph>
        Die von uns empfohlenen <strong>Online Spielotheken</strong> und <strong>Sportwetten-Anbieter</strong> sind durch offizielle Regulierungsbehörden lizenziert und überwacht. Diese Lizenzierungsstellen stellen sicher, dass die Anbieter die höchsten Standards für Spielerschutz einhalten.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Ihr Spielverhalten unter Kontrolle halten
      </Typography>
      <Typography variant="body1" paragraph>
        Bei <strong>EinfachNurZocken</strong> ermutigen wir unsere Spieler, das Glücksspiel als eine unterhaltsame Aktivität zu betrachten. Setzen Sie sich feste Grenzen und stellen Sie sicher, dass das Glücksspiel nicht in den Mittelpunkt Ihres Lebens rückt.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Kontakt für weitere Informationen
      </Typography>
      <Typography variant="body1" paragraph>
        Falls Sie Fragen oder Bedenken zum Thema Spielerschutz haben, zögern Sie nicht, uns zu kontaktieren. Wir stehen Ihnen gerne zur Verfügung und unterstützen Sie dabei, eine sichere und kontrollierte Glücksspielerfahrung zu gewährleisten.
      </Typography>
    </Box>
  );
}

export default Spielerschutz;