import React from 'react';
import { Box, Typography } from '@mui/material';

function AGB() {
  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h1" component="h1" gutterBottom>
        Allgemeine Geschäftsbedingungen (AGB)
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        1. Geltungsbereich
      </Typography>
      <Typography variant="body1" paragraph>
        Die folgenden Allgemeinen Geschäftsbedingungen (AGB) regeln das Vertragsverhältnis zwischen dem Betreiber der Website <strong>EinfachNurZocken</strong> (nachfolgend "Betreiber" oder "wir") und den Nutzern der Website (nachfolgend "Nutzer" oder "du"). Mit der Nutzung unserer Website erkennst du die Geltung dieser AGB in der jeweils aktuellen Fassung an.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        2. Leistungen des Betreibers
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>EinfachNurZocken</strong> bietet dir kostenlose Informationen und Vergleiche zu <strong>Online Spielotheken</strong> und <strong>Sportwetten-Anbietern</strong> an. Diese Informationen umfassen:
      </Typography>
      <ul>
        <li>Vergleich von Bonusangeboten und Promotionen,</li>
        <li>Bewertungen und Erfahrungsberichte zu Anbietern,</li>
        <li>Empfehlungen von seriösen Anbietern,</li>
        <li>News und aktuelle Informationen aus dem Bereich Online-Glücksspiel und Sportwetten.</li>
      </ul>
      <Typography variant="body1" paragraph>
        Es handelt sich hierbei um einen rein informativen Dienst. Wir vermitteln keine Glücksspiel-Dienstleistungen und sind kein Betreiber von Online Spielotheken oder Sportwetten-Plattformen.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        3. Haftungsausschluss
      </Typography>
      <Typography variant="body1" paragraph>
        Der Betreiber übernimmt keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität der auf <strong>EinfachNurZocken</strong> bereitgestellten Informationen. Alle Inhalte basieren auf sorgfältiger Recherche, können jedoch Änderungen unterliegen. Für Schäden, die durch die Nutzung von Informationen auf dieser Website entstehen, übernehmen wir keine Haftung, es sei denn, sie beruhen auf Vorsatz oder grober Fahrlässigkeit.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        4. Jugendschutz
      </Typography>
      <Typography variant="body1" paragraph>
        Die Nutzung von Glücksspiel-Angeboten ist in Deutschland erst ab einem Alter von 18 Jahren gestattet. <strong>EinfachNurZocken</strong> richtet sich ausschließlich an volljährige Nutzer. Minderjährigen ist die Nutzung der Website und der damit verbundenen Dienstleistungen strengstens untersagt.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        5. Verantwortungsvolles Spielen
      </Typography>
      <Typography variant="body1" paragraph>
        Wir fördern verantwortungsbewusstes Spielen und setzen uns für den <strong>Spielerschutz</strong> ein. Du wirst aufgefordert, deine Glücksspielaktivitäten im Blick zu behalten und dich an Hilfsorganisationen zu wenden, sollten du Anzeichen einer Spielsucht erkennen. Weitere Informationen findest du in unserem <a href="/spielerschutz">Spielerschutzbereich</a>.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        6. Verfügbarkeit der Website
      </Typography>
      <Typography variant="body1" paragraph>
        Der Betreiber bemüht sich, die Website möglichst unterbrechungsfrei zur Verfügung zu stellen. Dennoch können Ausfallzeiten, etwa aufgrund von Wartungsarbeiten oder technischen Störungen, nicht ausgeschlossen werden. Ein Anspruch auf permanente Verfügbarkeit besteht nicht.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        7. Geistiges Eigentum
      </Typography>
      <Typography variant="body1" paragraph>
        Alle auf <strong>EinfachNurZocken</strong> veröffentlichten Inhalte, insbesondere Texte, Bilder, Grafiken und Logos, sind urheberrechtlich geschützt. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des Betreibers.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        8. Verlinkung und externe Inhalte
      </Typography>
      <Typography variant="body1" paragraph>
        Unsere Website enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        9. Nutzerverhalten und Pflichten
      </Typography>
      <Typography variant="body1" paragraph>
        Du verpflichtest dich, bei der Nutzung von <strong>EinfachNurZocken</strong> die geltenden Gesetze zu beachten. Bei Verstößen gegen diese AGB behalten wir uns das Recht vor, dich von der Nutzung der Website auszuschließen.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        10. Datenschutz
      </Typography>
      <Typography variant="body1" paragraph>
        Der Schutz deiner persönlichen Daten ist uns wichtig. Informationen zur Erhebung, Verarbeitung und Nutzung personenbezogener Daten findest du in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        11. Änderungen der AGB
      </Typography>
      <Typography variant="body1" paragraph>
        Der Betreiber behält sich das Recht vor, diese AGB jederzeit zu ändern. Du wirst über Änderungen rechtzeitig informiert.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        12. Schlussbestimmungen
      </Typography>
      <Typography variant="body1" paragraph>
        Es gilt das Recht der Bundesrepublik Deutschland. Gerichtsstand ist der Sitz des Betreibers.
      </Typography>

      <Typography variant="h2" component="h2" gutterBottom>
        Kontakt
      </Typography>
      <Typography variant="body1" paragraph>
        Bei Fragen kannst du uns unter folgender Adresse erreichen:
        <br />
        <strong>EinfachNurZocken</strong>
        <br />
        Max Mustermann
        <br />
        Musterstraße 1, 12345 Musterstadt
        <br />
        E-Mail: info@einfachnurzocken.com
      </Typography>
    </Box>
  );
}

export default AGB;